import { ensureNumber, getAllActions } from "../comp/MissionUtils"

import { toZonedTime } from "date-fns-tz"

import { isAfter } from "date-fns/isAfter"

import { eachDayOfInterval } from "date-fns/eachDayOfInterval"

import differenceInBusinessDays from "date-fns/differenceInBusinessDays"
import { getTimesheetHoursFromTo } from "../comp/DateUtils"

import { isWithinInterval } from "date-fns/isWithinInterval"

import isWeekend from "date-fns/isWeekend"

import { endOfISOWeek } from "date-fns/endOfISOWeek"
import { getGreatestEndDate, getSmallestStartDate, getTimeUtc } from "../utils/dates"
import { getExchangeRates } from "../utils/exchange-rates"
import uniq from "lodash/uniq"
import flatMap from "lodash/flatMap"

import { isMissionBillable as isMissionBillableUtil } from "../utils/mf-utils"
import { getActionItemCostRevenue as getActionItemCostRevenueUtil } from "../utils/mf-utils"
import { getHourlyRates as getHourlyRatesUtil } from "../utils/mf-utils"
import { getLineItemCostAndRevenue as getLineItemCostAndRevenueUtil } from "../utils/mf-utils"
import { missionFinancials as missionFinancialsUtil } from "../utils/mf-utils"
import { getRoleItemCostAndRevenue as getRoleItemCostAndRevenueUtil } from "../utils/mf-utils"
import { getRaciBudgetForRole as getRaciBudgetForRoleUtil } from "../utils/mf-utils"

export function isMissionBillable(mission) {
    return isMissionBillableUtil(mission)
}

export async function fxFinancials({
    missions,
    mission,
    timesheets = [],
    invoices = [],
    orgData,
    from,
    to,
    app,
    exchangeRates,
}) {
    if (!missions && mission) {
        missions = [mission]
    }
    const currenciesUsed = uniq([...flatMap(missions, "currency"), ...flatMap(invoices || [], "currency")]).filter(
        (c) => !!c && c !== orgData?.currency
    )

    if (currenciesUsed.length && !exchangeRates) {
        try {
            exchangeRates = await getExchangeRates({
                baseCurrency: orgData.currency,
                currencies: currenciesUsed,
            })
        } catch (e) {
            throw e
        }
    }

    return missionFinancials({
        missions,
        from,
        to,
        exchangeRates,
        app: app,
        orgData,
    })
}

export function missionFinancials(props) {
    return missionFinancialsUtil(props)
}

export function getActionPercentComplete(action) {
    const { actionItems } = action

    return (
        actionItems?.reduce((cumm, ai) => (cumm += ensureNumber(ai.percentComplete)), 0) ||
        ensureNumber(action.percentComplete)
    )
}

export function getRoleItemCostAndRevenue(props) {
    return getRoleItemCostAndRevenueUtil(props)
}

export function mapPlanItemTimesheets(pi) {
    if (!pi.timesheets?.length) {
        return []
    } else {
        return (pi.timesheets || []).map((t) => {
            return {
                ...t,
                weekStart: t.weekStart,
                weekEnd: getTimeUtc(endOfISOWeek(toZonedTime(t.weekStart))),
                actionItems: [
                    {
                        days: t.dailyHours?.join(",") || "0,0,0,0,0,0,0,0",
                    },
                ],
            }
        })
    }
}

export function getLineItemCostAndRevenue({ planItem, from, to, mission, fxm }) {
    return getLineItemCostAndRevenueUtil({ planItem, from, to, mission, fxm })
}

export function getHourlyRates(planItem) {
    return getHourlyRatesUtil(planItem)
}

export function getMilestoneRevenue({ planItem, from, to }) {
    if (from && to && isAfter(from, to)) {
        return 0
    }

    let isInRange = !from && !to

    if (!isInRange) {
        try {
            isInRange = isWithinInterval(toZonedTime(planItem.endDate), {
                start: from,
                end: to,
            })
        } catch (e) {}
    }

    if (isInRange) {
        return ensureNumber(planItem.billRate)
    }

    return 0
}

export function getActionItemCostRevenue(props) {
    return getActionItemCostRevenueUtil(props)
}

export function getMissionHours({ mission, timesheets, from, to }) {
    let hours = {
        planned: 0,
        actual: 0,
    }

    mission.planItems
        .filter((p) => {
            return p.type === "person"
        })
        .forEach((planItem, i) => {
            const myHours = getHoursForRole({ planItem, timesheets, from, to })
            hours.planned += myHours.planned
            hours.actual += myHours.actual
        })

    return hours
}

export function getHoursForRole({ planItem, timesheets = [], from, to }) {
    let hours = {
        planned: 0,
        actual: 0,
    }

    let roleTimesheets = []

    if (timesheets.length === 0) {
        roleTimesheets = (planItem.timesheets || []).map((t) => {
            return {
                weekStart: t.weekStart,
                weekEnd: getTimeUtc(endOfISOWeek(toZonedTime(t.weekStart))),
                actionItems: [
                    {
                        days: t.dailyHours?.join(",") || "0,0,0,0,0,0,0,0",
                    },
                ],
            }
        })
    } else {
        timesheets.filter((ts) => ts.planItemId === planItem._id && ts.status === "approved")
    }

    if (!from || !to) {
        from = getSmallestStartDate(roleTimesheets.map((ts) => ({ startDate: ts.weekStart })))
        to = getGreatestEndDate(
            roleTimesheets.map((ts) => ({
                endDate: getTimeUtc(endOfISOWeek(toZonedTime(ts.weekStart))),
            }))
        )
    }

    hours.actual = 0

    if (planItem.raciOnTasks?.find((rt) => rt.actualHours)) {
        hours.actual = planItem.raciOnTasks?.reduce((cumm, obj) => cumm + ensureNumber(obj.actualHours), 0)
    } else {
        hours.actual = ensureNumber(getTimesheetHoursFromTo(roleTimesheets, from, to))
    }

    if (planItem.hoursNeeded || planItem.hoursNeeded === 0) {
        hours.planned = planItem.hoursNeeded || 0
    } else {
        const st = toZonedTime(planItem.startDate)
        const ed = toZonedTime(planItem.endDate)

        const d = differenceInBusinessDays(ed, st)

        hours.planned = d * 8
    }

    return hours
}

export function getCheckListCostAndRevenue(checklist) {
    let totalCost = 0
    let totalBill = 0

    checklist.forEach((st) => {
        const dur = st.actualDuration || st.duration
        totalCost += (st.rate || 0) * (dur === 0 ? 0 : dur || 1)
        totalBill += (st.billRate || 0) * (dur === 0 ? 0 : dur || 1)
    })

    return {
        estCost: totalCost,
        estRevenue: totalBill,
    }
}

export async function getExpenseTotal({ expenses, currency }) {
    let total = 0
    let rates

    if (expenses.find((ex) => ex.currency !== currency)) {
        rates = await getExchangeRates({ baseCurrency: currency })
    }

    expenses.forEach((exp, i) => {
        if (exp.status !== "approved" && exp.status !== "paid") return

        if (exp.currency !== currency) {
            const fx = rates ? rates[exp.currency] : 1
            const myTotal = ensureNumber(getExpenseAmount(exp))

            total += myTotal / fx
        } else {
            total += ensureNumber(getExpenseAmount(exp))
        }
    })

    return total
}

export function getExpenseAmount(exp, orgData) {
    let expenseAmount = ensureNumber(exp.amount)

    if (orgData?.expenseInvoiceOptions?.includes("tip")) {
        expenseAmount += ensureNumber(exp.tip)
    }
    if (orgData?.expenseInvoiceOptions?.includes("tax")) {
        expenseAmount += ensureNumber(exp.tax1) + ensureNumber(exp.tax2)
    }

    return expenseAmount
}
export function getAllChildrenThatAreActions(id, actionData) {
    let allChildren = []
    function getChildren(myId) {
        let immediateChildren = actionData.filter((d) => d.parentId === myId)
        allChildren = [...allChildren, ...immediateChildren]
        immediateChildren.forEach((item, i) => {
            getChildren(item._id)
        })
    }

    getChildren(id)

    return allChildren
}
export function getActionHours({ action, mission }) {
    const allActionKids = getAllChildrenThatAreActions(action._id, getAllActions(mission))

    const allTaskKids = [...(action.actionItems || []), ...flatMap(allActionKids, "actionItems")]

    let hours = 0

    if (allTaskKids.length === 0) {
        const d = eachDayOfInterval({
            start: toZonedTime(action.startDate),
            end: toZonedTime(action.endDate),
        }).filter((d) => !isWeekend(d)).length

        hours += d * 8
    } else {
        allTaskKids.forEach((ai, i) => {
            if (ai.actualDuration) {
                hours += ensureNumber(ai.actualDuration)
            } else {
                const d = eachDayOfInterval({
                    start: toZonedTime(ai.startDate),
                    end: toZonedTime(ai.endDate),
                }).filter((d) => !isWeekend(d)).length

                hours += ensureNumber(d * 8)
            }
        })
    }

    return ensureNumber(hours)
}

export function getBudgetForAction({ action, mission }) {
    let data = {
        estRevenue: 0,
        actualRevenue: 0,
        estCost: 0,
        actualCost: 0,
        hours: 0,
        percentComplete: 0,
    }

    const allActions = [action, ...getAllChildrenThatAreActions(action._id, getAllActions(mission))]

    const allTaskKidsForBudet = flatMap(
        allActions.filter((a) => !a.rate && !a.billRate),
        "actionItems"
    )

    allActions.forEach((ac, i) => {
        data.hours += getActionHours({ action: ac, mission })
    })

    allTaskKidsForBudet.forEach((ai, i) => {
        const b = getActionItemCostRevenue({ ai, mission })

        data.estRevenue += b.estRevenue
        data.actualRevenue += ensureNumber(Math.round(b.estRevenue * ensureNumber(ai.percentComplete / 100)))

        data.estCost += b.estCost
        data.actualCost += ensureNumber(Math.round(b.estCost * ensureNumber(ai.percentComplete / 100)))
    })

    return data
}

export const getRaciBudgetForRole = (planItem) => {
    return getRaciBudgetForRoleUtil(planItem)
}

export function getProcessTaskBudget({ ai, mission }) {
    return getActionItemCostRevenue({ ai, mission, totals: true })
}

export function getRoleRates({ role, orgData, mission }) {
    if (!role) {
        return role
    }

    if (mission.client) {
        const rc = orgData.rateCards?.find((rc) => rc.usedForId === mission.client)

        if (rc) {
            const roleRate = rc.ratesByRole.find((r) => r.roleId === role._id)

            if (roleRate) {
                return { ...role, ...roleRate, cost: roleRate.rate, _id: role._id }
            }
        }
    }

    return role
}
/*
export function getAmountForAction({ action, mission }) {
    let data = {
        estRevenue: 0,
        actualRevenue: 0,
        estCost: 0,
        actualCost: 0,
    }

    if (!action) {
        return data
    }

    const pc = getActionPercentComplete(action)
    const hours = getActionHours({ action, mission })

    if (action.billUnit === "One time") {
        data.estRevenue = ensureNumber(action.billRate)
        data.actualRevenue = ensureNumber(Math.round(ensureNumber(action.billRate) * (pc / 100)))
        data.estCost = ensureNumber(action.rate)
        data.actualCost = ensureNumber(Math.round(ensureNumber(action.rate) * (pc / 100)))
    } else if (action.billUnit === "Hourly") {
        data.estRevenue = ensureNumber(action.billRate * hours)
        data.actualRevenue = ensureNumber(action.billRate * hours)
        data.estCost = ensureNumber(action.rate * hours)
        data.actualCost = ensureNumber(Math.round(action.rate * hours * (pc / 100)))
    }

    return data
}
function getChildren(array, id) {
    return array.reduce((r, obj) => {
        if (obj.parentId === id) {
            r.push(obj, ...getChildren(array, obj._id))
        }
        return r
    }, [])
}*/
