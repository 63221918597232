import {
    isAfter,
    isBefore,
    endOfMonth,
    isSameMonth,
    eachWeekOfInterval,
    eachMonthOfInterval,
    eachDayOfInterval,
    areIntervalsOverlapping,
    endOfDay,
    startOfDay,
    isSameDay,
    isWithinInterval,
    endOfISOWeek,
    isSameISOWeek,
    getISODay,
    differenceInBusinessDays,
    isFirstDayOfMonth,
    isLastDayOfMonth,
    startOfMonth,
    isMonday,
    isFriday,
    isWeekend,
    startOfISOWeek,
    addDays,
    addMonths,
    format,
} from "date-fns"
import flatMap from "lodash/flatMap"
import { getSmallestStartDate } from "../comp/DateUtils"
import { fromZonedTime } from "date-fns-tz"
import { toZonedTime } from "date-fns-tz"
//import { getFxForTheDay } from "../utils/exchange-rates"
import { getTimeEntryHours } from "../comp/DateUtils"
//import { getFxForTheDay } from "../utils/exchange-rates"

const getAmountBasedOnRate = ({ planItem, rateToUse, timeToUse, from, to, fxm }) => {
    let amount = 0

    if (from && to && isAfter(from, to)) {
        return 0
    }

    if (!fxm) {
        fxm = () => 1
    }

    function getStartDateToUse(planItem, from) {
        const piStartDate = toZonedTime(planItem.startDate)

        if (!from) {
            return piStartDate
        }
        if (isAfter(from, piStartDate)) {
            return from
        }

        return piStartDate
    }
    function getEndDateToUse(planItem, to) {
        const piEndDate = toZonedTime(planItem.endDate)

        if (!to) {
            return piEndDate
        }
        if (isAfter(piEndDate, to)) {
            return to
        }

        return piEndDate
    }

    let startDateToUse = getStartDateToUse(planItem, from) //getStartDateToUsefrom && isBefore from || toZonedTime(planItem.startDate)
    let endDateToUse = getEndDateToUse(planItem, to)
    let adjustedEndDate = startOfDay(addDays(endDateToUse, 1))

    const hourlyRates = getHourlyRates(planItem)

    const dayRate = ensureNumber(hourlyRates[rateToUse]) * 8

    const myRate = ensureNumber(planItem[rateToUse])

    if (planItem[timeToUse] === "One time") {
        amount = (planItem[rateToUse] || 0) * (planItem.quantity || 1)
    } else {
        if (planItem[timeToUse] === "Yearly") {
            const bizDays = differenceInBusinessDays(adjustedEndDate, startDateToUse)
            amount = bizDays * 8 * hourlyRates[rateToUse]
        } else if (planItem[timeToUse] === "Monthly") {
            let fullMonths = 0

            try {
                fullMonths = eachMonthOfInterval({
                    start: startDateToUse,
                    end: endDateToUse,
                }).length
            } catch (e) {}

            if (!isFirstDayOfMonth(startDateToUse)) {
                fullMonths--
            }
            if (!isLastDayOfMonth(endDateToUse)) {
                fullMonths--
            }

            if (fullMonths === 0 || fullMonths < 0) {
                const bizDays = differenceInBusinessDays(adjustedEndDate, startDateToUse)

                amount = bizDays * dayRate
            } else {
                amount = fullMonths * myRate

                if (!isFirstDayOfMonth(startDateToUse)) {
                    //get partial month days at the start
                    const daysTillFullMonth = differenceInBusinessDays(endOfMonth(startDateToUse), startDateToUse)

                    amount += daysTillFullMonth * dayRate
                }
                if (!isLastDayOfMonth(endDateToUse)) {
                    //get partial month days at the end
                    const daysAfterFullMonth = differenceInBusinessDays(adjustedEndDate, startOfMonth(endDateToUse))

                    amount += daysAfterFullMonth * dayRate
                }
            }
        } else if (planItem[timeToUse] === "Weekly") {
            let fullWeeks = eachWeekOfInterval(
                {
                    start: startDateToUse,
                    end: endDateToUse,
                },
                {
                    weekStartsOn: 1,
                }
            ).length

            if (!isMonday(startDateToUse)) {
                fullWeeks--
            }
            if (!isFriday(endDateToUse) && !isWeekend(endDateToUse)) {
                fullWeeks--
            }

            if (fullWeeks === 0 || fullWeeks < 0) {
                const bizDays = differenceInBusinessDays(adjustedEndDate, startDateToUse)
                amount = bizDays * dayRate
            } else {
                amount = fullWeeks * myRate

                if (!isMonday(startDateToUse)) {
                    //get partial month days at the start
                    const daysTillFullWeek = differenceInBusinessDays(endOfISOWeek(startDateToUse), startDateToUse)

                    amount += daysTillFullWeek * dayRate
                }
                if (!isFriday(endDateToUse) && !isWeekend(endDateToUse)) {
                    //get partial month days at the end
                    const daysAfterFullWeek = differenceInBusinessDays(adjustedEndDate, startOfISOWeek(endDateToUse))

                    amount += daysAfterFullWeek * dayRate
                }
            }
        } else if (planItem[timeToUse] === "Daily") {
            const bizDays = differenceInBusinessDays(adjustedEndDate, startDateToUse)
            amount = bizDays * myRate
        } else if (planItem[timeToUse] === "Hourly") {
            const bizzDays = differenceInBusinessDays(adjustedEndDate, startDateToUse)

            amount = bizzDays * myRate * 8
        }
    }

    return amount * fxm(endDateToUse)
}

function getObjectId(id, handlePopulatedRef = true) {
    if (!id) {
        return id
    }

    if (typeof id === "string") {
        return id
    }

    if (id._id && handlePopulatedRef === true) {
        // Populated reference. Re-iterate once with child _id
        return getObjectId(id._id, false)
    }

    return id
}

const isArchived = (obj) => {
    if (!obj) return null
    return obj.isArchived || obj._archived || obj.isDeleted
}

function rolesRequireApproval({ mission, orgData }) {
    if (!orgData) return false
    const myDepartments = mission.departmentTags?.length
        ? mission.departmentTags
              ?.map((did) => {
                  return orgData.departments?.find((d) => d._id === did)
              })
              .filter((d) => !!d && !isArchived(d))
        : []

    if (
        myDepartments?.filter((d) => d.rolesRequireApproval).length === mission.departmentTags?.length &&
        mission.departmentTags?.length > 0
    ) {
        return true
    }

    return false
}

function getTimeUtc(date) {
    try {
        const utcDate = fromZonedTime(date)
        return utcDate.getTime()
    } catch (err) {
        return undefined
    }
}

export function mapPlanItemTimesheets(pi) {
    if (!pi.timesheets?.length) {
        return []
    } else {
        return (pi.timesheets || []).map((t) => {
            return {
                ...t,
                weekStart: t.weekStart,
                weekEnd: getTimeUtc(endOfISOWeek(toZonedTime(t.weekStart))),
                actionItems: [
                    {
                        days: t.dailyHours?.join(",") || "0,0,0,0,0,0,0,0",
                    },
                ],
            }
        })
    }
}

export const getRaciBudgetForRole = (planItem, timesheets = []) => {
    let totals = {
        hoursNeeded: 0,
        estCost: 0,
        estRevenue: 0,
        actualCost: 0,
        actualRevenue: 0,
        actualHours: 0,
    }

    const rt = planItem.raciOnTasks

    if (rt?.length) {
        const hrlyRate = getHourlyRates(planItem)

        rt.forEach((r, i) => {
            totals.estCost += ensureNumber(r.hoursNeeded) * ensureNumber(hrlyRate.rate)
            totals.estRevenue += ensureNumber(r.hoursNeeded) * ensureNumber(hrlyRate.billRate)
            totals.hoursNeeded += ensureNumber(r.hoursNeeded)

            let myTsTaskHours = 0

            if (!r.actualHours) {
                const mySheets = timesheets.filter((t) => t.planItemId === planItem._id)

                mySheets.forEach((ts, i) => {
                    ts.actionItems.forEach((a, i) => {
                        myTsTaskHours += a.days.split(",").reduce((cumm, obj) => (cumm += ensureNumber(obj)), 0)
                    })
                })
            }

            const myActualHours = r.actualHours ? ensureNumber(r.actualHours) : myTsTaskHours
            totals.actualHours += myActualHours
            totals.actualCost += ensureNumber(myActualHours) * ensureNumber(hrlyRate.rate)
            totals.actualRevenue += ensureNumber(myActualHours) * ensureNumber(hrlyRate.billRate)
        })

        totals.estCost += ensureNumber(totals.hoursNeeded) * ensureNumber(hrlyRate.rate)
        totals.estRevenue += ensureNumber(totals.hoursNeeded) * ensureNumber(hrlyRate.billRate)
    }

    return totals
}

function isWithinOptionalInterval(date, start, end) {
    // If both boundaries are missing, everything is "within"
    if (!start && !end) return true

    // If we only have a start, require date >= start
    if (start && !end) {
        return date.getTime() >= start.getTime()
    }

    // If we only have an end, require date <= end
    if (!start && end) {
        return date.getTime() <= end.getTime()
    }

    // If both boundaries exist, use date-fns isWithinInterval
    return isWithinInterval(date, { start, end })
}

export function distributeHoursExcludingWeekendsIsoWeekly(
    distributionStart,
    distributionEnd,
    totalHours,
    filterStart,
    filterEnd
) {
    // Utility function to get the earlier of two dates
    const getEarlierDate = (date1, date2) => (date1 < date2 ? date1 : date2)

    // 1. Get all ISO weeks in [distributionStart, distributionEnd]
    //    ISO week means Monday is the start of the week.
    const weeks = eachWeekOfInterval(
        {
            start: distributionStart,
            end: distributionEnd,
        },
        { weekStartsOn: 1 } // ISO weeks start on Monday
    )

    // 2. For each week, determine how many weekdays (Mon-Fri) it has
    //    within [weekStart, endOfWeek(weekStart)] but capped by distributionEnd
    const weeklyWorkdays = weeks.map((weekStart) => {
        // Get the actual end of this ISO week or distributionEnd, whichever is earlier
        const weekEnd = getEarlierDate(endOfISOWeek(weekStart), distributionEnd)

        // Count only weekdays (Mon=1..Fri=5); getDay() => 1..5
        let count = 0
        for (let day = weekStart; day <= weekEnd; day = addDays(day, 1)) {
            const dayOfWeek = day.getDay()
            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                count++
            }
        }

        return { weekStart, count }
    })

    // 3. Sum total workdays across all ISO weeks
    const totalWorkdays = weeklyWorkdays.reduce((sum, { count }) => sum + count, 0)

    // If there are no valid workdays, return empty
    if (totalWorkdays === 0) {
        return []
    }

    // 4. Distribute totalHours across all workdays
    const baseHours = Math.floor(totalHours / totalWorkdays)
    const leftover = totalHours % totalWorkdays

    // 5. Build a weekly distribution array with baseHours * numberOfWorkdays
    let weeklyDistribution = weeklyWorkdays.map(({ weekStart, count }) => ({
        weekStart,
        hours: baseHours * count, // base allotment for that week's count of workdays
    }))

    // 6. Distribute leftover hours from the end backward
    let remainingLeftover = leftover
    for (let i = weeklyDistribution.length - 1; i >= 0 && remainingLeftover > 0; i--) {
        const { count } = weeklyWorkdays[i]
        const extraHours = Math.min(remainingLeftover, count)
        weeklyDistribution[i].hours += extraHours
        remainingLeftover -= extraHours
    }

    // 7. Filter out distributions if their weekStart is outside [filterStart, filterEnd]
    //    (assuming entire weeks should be filtered in or out)
    weeklyDistribution = weeklyDistribution.filter(({ weekStart }) =>
        isWithinOptionalInterval(weekStart, filterStart, filterEnd)
    )

    return weeklyDistribution
}

export function distributeHoursExcludingWeekends(
    distributionStart,
    distributionEnd,
    totalHours,
    filterStart,
    filterEnd
) {
    // 1. Get ALL days in [distributionStart, distributionEnd]
    const allDays = eachDayOfInterval({
        start: distributionStart,
        end: distributionEnd,
    })

    // 2. Exclude weekends (Sat=6, Sun=0)
    const fullWorkdays = allDays.filter((day) => {
        const dayOfWeek = day.getDay() // 0=Sun, 6=Sat
        return dayOfWeek !== 0 && dayOfWeek !== 6
    })

    // If there are no valid workdays, return empty
    if (fullWorkdays.length === 0) {
        return []
    }

    // 3. Distribute totalHours across ALL workdays in the FULL range
    const totalWorkdays = fullWorkdays.length
    const baseHours = Math.floor(totalHours / totalWorkdays)
    const leftover = totalHours % totalWorkdays

    // Initialize distribution array with baseHours for each workday
    const distribution = new Array(totalWorkdays).fill(baseHours)

    // 4. Distribute leftover hours from the "end" backward
    for (let i = 0; i < leftover; i++) {
        const index = totalWorkdays - 1 - i
        distribution[index] += 1
    }

    // 5. Build the final daily array of { date, hours }
    let dailyDistribution = fullWorkdays.map((date, i) => ({
        date,
        hours: distribution[i],
    }))

    // 6. Filter by [filterStart, filterEnd] if provided
    dailyDistribution = dailyDistribution.filter(({ date }) => isWithinOptionalInterval(date, filterStart, filterEnd))

    return dailyDistribution
}

export function getRoleItemCostAndRevenue({
    planItem,
    from,
    to,
    mission,
    roleTimesheets = [],
    fxm,
    forceCalculate,
    exchangeRates,
    app,
    slim,
}) {
    if (exchangeRates && !fxm) {
        fxm = (d) => {
            //exchange
            return 1
        }
    }

    let data = slim
        ? { estCost: 0, actualCost: 0, actualHours: 0, estRevenue: 0, actualRevenue: 0 }
        : {
              actualHours: 0,
              actualCost: 0,
              estCost: 0,
              estRevenue: 0,
              actualRevenue: 0,
          }

    if (
        from &&
        to &&
        !areIntervalsOverlapping(
            {
                start: from,
                end: to,
            },
            {
                start: toZonedTime(planItem.startDate),
                end: toZonedTime(planItem.endDate),
            }
        )
    ) {
        return data
    }
    if (!mission || !planItem) {
        return data
    }

    if (planItem?.createdFromGtCrm) {
        return data
    }

    if (!fxm) {
        fxm = () => 1
    }

    let hourlyRates = getHourlyRates(planItem)

    if (from && to && isAfter(from, to)) {
        return data
    }

    if (roleTimesheets?.length === 0) {
        roleTimesheets = mapPlanItemTimesheets(planItem)
    }

    if (roleTimesheets.length) {
        if (!from) from = toZonedTime(planItem.startDate)
        if (!to) to = toZonedTime(planItem.endDate)

        roleTimesheets.forEach((ts, i) => {
            const isTsIn = areIntervalsOverlapping(
                {
                    start: from,
                    end: to,
                },
                { start: toZonedTime(ts.weekStart), end: toZonedTime(ts.weekEnd) }
            )

            if (isTsIn) {
                ts.actionItems.forEach((tsa, i) => {
                    //let isAConflict = conflictingTimesheetActionItems.find((cf) => cf._id === tsa.actionItemId)
                    //if (!isAConflict) {
                    let startIndex = 0
                    let endIndex = 6

                    if (isSameISOWeek(toZonedTime(ts.weekStart), from)) {
                        startIndex = getISODay(from) - 1
                    }
                    if (isSameISOWeek(toZonedTime(ts.weekEnd), to)) {
                        endIndex = getISODay(to) - 1
                    }

                    let myHoursHere = ensureNumber(getTimeEntryHours(tsa, startIndex, endIndex))

                    let tsRates = getHourlyRates(ts.snapshot?.planItem || ts.planItem || ts)

                    data.actualHours += myHoursHere
                    data.actualCost += myHoursHere * tsRates.rate * fxm(toZonedTime(ts.weekEnd))

                    data.actualRevenue +=
                        mission.billUsingMilestones || mission.notBillable
                            ? 0
                            : myHoursHere * tsRates.billRate * fxm(toZonedTime(ts.weekEnd))
                    //}
                })
            }
        })
        //}
    }

    if (mission.isProcess || mission.projectType === "mx-gantt") {
        const b = getRaciBudgetForRole(planItem, roleTimesheets)
        data.estCost = b.estCost
        data.estRevenue = b.estRevenue
        data.actualCost = b.actualCost
        data.actualRevenue = b.actualRevenue
        data.actualHours = b.actualHours

        return data
    }

    if (planItem.hoursNeeded === 0 && planItem.hoursNeeded !== null) {
        //no dice
        return data
    } else if (planItem.hoursNeeded > 0) {
        const dailyDistribution = distributeHoursExcludingWeekends(
            toZonedTime(planItem.startDate),
            toZonedTime(planItem.endDate),
            planItem.hoursNeeded,
            from,
            to
        )

        dailyDistribution.forEach((obj, i) => {
            data.estCost += ensureNumber(obj.hours * hourlyRates.rate * fxm(toZonedTime(planItem.endDate)))

            data.estRevenue =
                (mission.billUsingMilestones || mission.notBillable) && !forceCalculate
                    ? 0
                    : ensureNumber(obj.hours * hourlyRates.billRate * fxm(toZonedTime(planItem.endDate)))
        })

        return data
    } else {
        let isInRange = isRecurringPlanItemInRangeCheck({ planItem, from, to, mission })

        if (isInRange) {
            //Main logic
            let cost = 0
            let bill = 0

            //Do cost
            cost = getAmountBasedOnRate({ planItem, rateToUse: "rate", timeToUse: "rateTime", from, to, fxm })

            bill = getAmountBasedOnRate({ planItem, rateToUse: "billRate", timeToUse: "billUnit", from, to, fxm })

            //const subMe = data.conflictingHours ? getHourlyRates(planItem).billRate * data.conflictingHours : 0

            data.estCost = ensureNumber(cost)
            data.estRevenue = ensureNumber(bill) //mission.billUsingMilestones ? 0 : bill - subMe) //For stupid tasks that are fixed bid
            return data
        } else {
            return data
        }
    }
}

export function getMonthKey(year, i) {
    const std = addMonths(new Date(`${year}-01-01`), i)
    return format(std, "yyyy-MM-dd")
}

export function isRecurringPlanItemInRangeCheck({ planItem, from, to, mission }) {
    if (!from && !to) {
        return true
    }

    if (!planItem.startDate || !planItem.endDate) {
        return false //Bad data
    }

    if (!from) from = toZonedTime(planItem.startDate)
    if (!to) to = toZonedTime(planItem.endDate)

    let isIn = false

    const utStart = toZonedTime(planItem.startDate)
    const utEnd = endOfDay(toZonedTime(planItem.endDate))

    try {
        isIn = areIntervalsOverlapping(
            {
                start: from,
                end: to,
            },
            {
                start: utStart,
                end: utEnd,
            }
        )
    } catch (e) {}

    return isIn
}

export function ensureNumber(val) {
    let newVal = val
    if (newVal === null) return 0
    if (newVal === undefined) return 0
    if (typeof newVal === "string") {
        newVal = newVal.replaceAll(",", "")
    }
    if (Number.isNaN(+newVal)) return 0
    return +newVal
}

export function isMissionBillable(mission) {
    if (mission.notBillable) {
        return false
    }

    if (mission.isModel) {
        return true
    }
    if (mission.org && mission.isTemplate) {
        return true
    }

    return mission.client
}

export function getHourlyRates(planItem) {
    if (!planItem) {
        return { billRate: 0, rate: 0 }
    }

    const rate = ensureNumber(planItem.rate)
    const billRate = ensureNumber(planItem.billRate)

    const factor = {
        Hourly: { rate: 1, billRate: 1 },
        Weekly: { rate: 1 / 40, billRate: 1 / 40 },
        Monthly: { rate: (1 / 40) * (12 / 52), billRate: (1 / 40) * (12 / 52) },
        Daily: { rate: 1 / 8, billRate: 1 / 8 },
        Yearly: { rate: (1 / 40) * (1 / 52), billRate: (1 / 40) * (1 / 52) },
    }

    const { rate: rateFactor, billRate: billRateFactor } = factor[planItem.rateTime] || { rate: 0, billRate: 0 }

    const myRate = rate * rateFactor
    const myBillRate = billRate * billRateFactor

    return {
        billRate: ensureNumber(myBillRate),
        rate: ensureNumber(myRate),
    }
}

export function getAllActionItems(mission) {
    if (!mission?.planItems) {
        return []
    }
    let allActions = mission.planItems.reduce((cum, p) => [...cum, ...(p.actions || [])], [])
    return (allActions || []).reduce((cum, p) => [...cum, ...(p?.actionItems || [])], [])
}

const getIntervalOfData = ({ planItem, mission, from, to, fxm }) => {
    let data = {
        estCost: 0,
        estRevenue: 0,
    }

    if (!fxm) {
        fxm = () => 1
    }

    const piStartDate = toZonedTime(planItem.startDate)
    const piEndDate = toZonedTime(planItem.endDate)

    const methodToUse =
        planItem.rateTime === "Monthly"
            ? eachMonthOfInterval
            : planItem.rateTime === "Weekly"
            ? eachWeekOfInterval
            : eachDayOfInterval

    const isInRange = isRecurringPlanItemInRangeCheck({ planItem, from, to, mission })

    if (isInRange) {
        const startDateToUse = !from
            ? piStartDate
            : isAfter(piStartDate, from) || isSameMonth(piStartDate, from)
            ? piStartDate
            : from
        const endDateToUse = !to ? piEndDate : isAfter(to, piEndDate) || isSameMonth(piEndDate, to) ? piEndDate : to

        let numberOfIterations = 0
        let estC = 0
        let estR = 0

        numberOfIterations = methodToUse(
            {
                start: startDateToUse,
                end: endDateToUse,
            },
            { weekStartsOn: 1 }
        ).forEach((time, i) => {
            estC += ensureNumber(planItem.rate) * ensureNumber(planItem.quantity) * fxm(time)
            estR += ensureNumber(planItem.billRate) * ensureNumber(planItem.quantity) * fxm(time)
        })

        return {
            estCost: ensureNumber(estC),
            estRevenue: ensureNumber(estR),
        }
    } else {
        return data
    }
}

export function isOneTimePlanItemInRangeCheck({ planItem, from, to }) {
    if (!from && !to) {
        return true
    }

    if (!planItem.startDate || !planItem.endDate) {
        return false //Bad data
    }

    if (!from) from = toZonedTime(planItem.startDate)
    if (!to) to = toZonedTime(planItem.endDate)

    let isIn = false

    try {
        const st = startOfDay(toZonedTime(planItem.endDate))
        const ed = endOfDay(toZonedTime(planItem.endDate)) //catch the last date

        isIn =
            areIntervalsOverlapping(
                {
                    start: startOfDay(from),
                    end: endOfDay(to),
                },
                {
                    start: st,
                    end: ed,
                }
            ) ||
            isSameDay(from, st) ||
            isSameDay(to, ed) ||
            isWithinInterval(ed, {
                start: startOfDay(from),
                end: endOfDay(to),
            })
    } catch (e) {
        throw e
    }

    return isIn
}

export function getLineItemCostAndRevenue({ planItem, from, to, mission, fxm }) {
    let data = {
        estCost: 0,
        estRevenue: 0,
        actualCost: 0,
        actualRevenue: 0,
    }

    if (!fxm) {
        fxm = () => 1
    }

    if (from && to && isAfter(from, to)) {
        return data
    }

    if (!from && !to && planItem.rateTime === "One time") {
        data.estCost = ensureNumber(planItem.rate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))
        data.estRevenue =
            ensureNumber(planItem.billRate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))

        if (isBefore(toZonedTime(planItem.endDate), endOfMonth(new Date()))) {
            data.actualCost =
                ensureNumber(planItem.rate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))
            data.actualRevenue =
                ensureNumber(planItem.billRate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))
        }
    } else if (planItem.rateTime === "One time") {
        const isInRange = isOneTimePlanItemInRangeCheck({ planItem, from, to })

        if (isInRange) {
            data.estCost = ensureNumber(planItem.rate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))
            data.estRevenue =
                ensureNumber(planItem.billRate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))
        }

        if (isBefore(toZonedTime(planItem.endDate), endOfMonth(new Date()))) {
            data.actualCost =
                ensureNumber(planItem.rate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))
            data.actualRevenue =
                ensureNumber(planItem.billRate) * (planItem.quantity || 1) * fxm(toZonedTime(planItem.endDate))
        }
    } else {
        return getIntervalOfData({ planItem, from, to, mission, fxm })
    }

    return data
}

export function getActionItemCostRevenue({ ai, mission, totals, timesheets = [] }) {
    let estCost = 0,
        estRevenue = 0,
        hoursNeeded = 0,
        actualCost = 0,
        actualRevenue = 0,
        actualHours = 0

    const rolePlanItems = mission.planItems.filter(
        (p) => p.type === "person" && p.raciOnTasks?.find((r) => r.taskId === ai._id)
    )

    if (ai.metaType === 4 && ai.billRate && ai.pinType === "fundable-milestone") {
        estRevenue += ai.billRate
        if (ai.percentComplete) {
            actualRevenue += ai.billRate
        }
    } else {
        rolePlanItems.forEach((pi) => {
            const hrlyRate = getHourlyRates(pi)

            pi.raciOnTasks //sssss
                .filter((r) => r.taskId === ai._id)
                .forEach((r, i) => {
                    estCost += ensureNumber(r.hoursNeeded) * ensureNumber(hrlyRate.rate)
                    estRevenue += ensureNumber(r.hoursNeeded) * ensureNumber(hrlyRate.billRate)
                    hoursNeeded += ensureNumber(r.hoursNeeded)

                    let myTsTaskHours = 0

                    if (!r.actualHours) {
                        const mySheets = timesheets.filter(
                            (t) => t.planItemId === pi._id && t.actionItems.find((a) => a.actionItemId === ai._id)
                        )

                        mySheets.forEach((ts, i) => {
                            ts.actionItems.forEach((a, i) => {
                                if (a.actionItemId === ai._id) {
                                    myTsTaskHours += a.days
                                        .split(",")
                                        .reduce((cumm, obj) => (cumm += ensureNumber(obj)), 0)
                                }
                            })
                        })
                    }

                    const myActualHours = r.actualHours ? ensureNumber(r.actualHours) : myTsTaskHours
                    actualHours += myActualHours
                    actualCost += ensureNumber(myActualHours) * ensureNumber(hrlyRate.rate)
                    actualRevenue += ensureNumber(myActualHours) * ensureNumber(hrlyRate.billRate)
                })
        })
    }

    if (totals)
        mission.planItems.forEach((p, i) => {
            if (p.linkedToTask === ai._id && p.type === "cost") {
                const budget = getLineItemCostAndRevenue({
                    planItem: p,
                    mission,
                })
                estCost += budget.estCost
                estRevenue += budget.estRevenue
                actualCost += budget.actualCost
                actualRevenue += budget.actualRevenue
            }
        })

    if (!isMissionBillable(mission)) {
        estRevenue = 0
    }

    return {
        estCost,
        estRevenue,
        hoursNeeded,
        actualHours,
        actualCost: actualCost,
        actualRevenue: actualRevenue ? actualRevenue : estRevenue ? estRevenue * (ai.percentComplete / 100) : 0,
    }
}

export function missionFinancials({
    missions,
    mission,
    timesheets = [],
    invoices = [],
    orgData,
    from,
    to,
    exchangeRates,
    app,
    dead,
    breakAt,
    slim,
}) {
    let data = slim
        ? { estCost: 0, actualCost: 0, actualHours: 0, estRevenue: 0, budgetAvailable: 0 }
        : {
              estCost: 0,
              actualCost: 0,
              actualHours: 0,
              estRevenue: 0,
              invoiced: 0,
              collected: 0,
              actualRevenue: 0,
              peopleCost: 0,
              peopleRev: 0,
              lineItemCost: 0,
              lineItemRev: 0,
              lineItemActualCost: 0,
              lineItemActualRev: 0,
              budgetAvailable: 0,
          }

    if (dead) {
        return data
    }

    if (from && to && isAfter(from, to)) {
        return data
    }

    if (mission && !missions) {
        missions = [mission]
    }

    missions.forEach((mission, i) => {
        const approvalNeeded = orgData ? rolesRequireApproval({ mission, orgData }) : null

        const isBillable = isMissionBillable(mission) || mission.isModel

        let useMultiplier = false

        if (exchangeRates) {
            useMultiplier = exchangeRates && mission.currency !== orgData.currency
        }

        const fxm = (d) => {
            /*return getFxForTheDay({
                useMultiplier,
                currency: mission.currency,
                exchangeRates,
                day: d,
                app,
            })*/

            return 1
        }

        data.budgetAvailable += (mission.budgetAvailable || 0) * fxm(toZonedTime(mission.planEndDate))

        const roles = mission.planItems.filter((p) => {
            if (!p?.type) {
                return false
            }
            if (p.type !== "person") {
                return false
            }

            if (approvalNeeded && !p.createdFromGtCrm) {
                return false
            }
            return true
        })

        if (mission.projectType === "mx-gantt") {
            getAllActionItems(mission).forEach((ai, i) => {
                const aiData = getActionItemCostRevenue({ ai, mission, timesheets })
                data.estCost += aiData.estCost
                data.estRevenue += aiData.estRevenue
                data.actualRevenue += aiData.actualRevenue
            })
        }

        const missionTimesheets = timesheets.filter(
            (ts) => ts.status === "approved" && getObjectId(ts.mission) === mission._id
        )

        roles.forEach((planItem, i) => {
            const roleTimesheets = missionTimesheets.filter(
                (ts) => ts.planItemId === planItem._id && ts.status === "approved"
            )

            let costRevenueForRole = getRoleItemCostAndRevenue({
                planItem,
                mission,
                from,
                to,
                roleTimesheets,
                fxm,
                slim,
            })

            if (mission.projectType !== "mx-gantt") {
                data.estCost += ensureNumber(costRevenueForRole.estCost)
                if (!slim) data.peopleCost += ensureNumber(costRevenueForRole.estCost)
            }

            data.actualCost += ensureNumber(costRevenueForRole.actualCost)

            if (isBillable && !mission.billUsingMilestones) {
                if (mission.projectType !== "mx-gantt") {
                    data.estRevenue += ensureNumber(costRevenueForRole.estRevenue)
                    if (!slim) data.peopleRev += ensureNumber(costRevenueForRole.estRevenue)
                }

                data.actualRevenue += ensureNumber(costRevenueForRole.actualRevenue)
            }
        })

        const lineItems = mission.planItems.filter((p) => p.type === "cost")

        lineItems.forEach((planItem, i) => {
            const costRevenueForLineItem = getLineItemCostAndRevenue({ planItem, mission, from, to, fxm })

            if (!slim) {
                data.lineItemActualCost += ensureNumber(costRevenueForLineItem.actualCost)
                data.lineItemCost += ensureNumber(costRevenueForLineItem.estCost)
            }

            data.estCost += ensureNumber(costRevenueForLineItem.estCost)
            data.actualCost += ensureNumber(costRevenueForLineItem.actualCost)

            if (isBillable) {
                data.actualRevenue += ensureNumber(costRevenueForLineItem.actualRevenue)
                data.estRevenue += ensureNumber(costRevenueForLineItem.estRevenue)

                if (!slim) {
                    data.lineItemRev += ensureNumber(costRevenueForLineItem.estRevenue)
                    data.lineItemActualRev += ensureNumber(costRevenueForLineItem.actualRevenue)
                }
            }
        })

        if (mission.billUsingMilestones) {
            let msRevenue = 0
            let msActualRev = 0
            mission.planItems.forEach((ms, i) => {
                if (ms.type !== "fundable_milestone") {
                    return
                }

                let isInRange = false

                try {
                    isInRange =
                        !from && !to
                            ? true
                            : isWithinInterval(toZonedTime(ms.endDate), {
                                  start: from,
                                  end: to,
                              })
                } catch (e) {}

                if (isInRange) {
                    const fm = fxm(toZonedTime(ms.endDate))
                    msRevenue += ensureNumber(ms.billRate || ms.amount) * fm
                    if (ms.approved) {
                        msActualRev += ensureNumber(ms.billRate || ms.amount) * fm
                    }
                }
            })
            data.actualRevenue += msActualRev
            data.estRevenue += msRevenue
        }

        getAllActionItems(mission).forEach((ai, i) => {
            ai.approvals?.forEach((cr, i) => {
                if (cr.approvedOn) {
                    if (from && to && !isWithinInterval(toZonedTime(cr.approvedOn), { start: from, end: to })) {
                        return
                    } else if (from && !to && isBefore(toZonedTime(cr.approvedOn), from)) {
                        return
                    }

                    if (isBillable) {
                        data.estRevenue += ensureNumber(cr.billRate)
                    } else {
                        data.estCost += ensureNumber(cr.billRate)
                    }
                }
            })
        })

        if (!slim && invoices.length) {
            let invoiceData
            try {
                /*invoiceData = getInvoicedAmounts({
                    invoices: invoices.filter((inv) => inv?.missionIds?.includes(mission._id)),
                    from,
                    to,
                })*/
            } catch (e) {
                throw e
            }

            data.invoiced += invoiceData?.invoiced || 0
            data.collected += invoiceData?.collected || 0
        }
    })

    return data
}

export const mapPeopleDates = ({ orgData, productivePlanItemsByPerson, from, to }) => {
    let mapObj = {}

    orgData.people.forEach((per) => {
        let myEd = per.endDate ? toZonedTime(per.endDate) : to

        const myPlanItems = productivePlanItemsByPerson[per?.ref?._id || per._id || per]

        let mySmallestTimesheetDate = getSmallestStartDate(
            flatMap(myPlanItems || [], "timesheets")
                .filter((t) => !!t)
                .map((t) => ({ startDate: t.weekStart }))
        )
        let mySmallestRoleDate = getSmallestStartDate(myPlanItems || [])
        let personCreatedDate = per.createdAt ? new Date(per.createdAt) : null
        let personStartDate = per.startDate ? toZonedTime(per.startDate) : null

        let baseStart = personStartDate || personCreatedDate || from

        let smallestDate = new Date(
            Math.min(
                mySmallestTimesheetDate?.getTime() || Infinity,
                mySmallestRoleDate?.getTime() || Infinity,
                baseStart?.getTime()
            )
        )

        if (isAfter(smallestDate, to)) {
        } else {
            if (isBefore(smallestDate, from)) {
                smallestDate = from
            }

            if (isBefore(smallestDate, to)) {
                mapObj[per?.ref?._id || per._id || per] = {
                    startDate: smallestDate,
                    endDate: isBefore(myEd, to) ? myEd : to,
                }
            }
        }
    })

    return mapObj
}
