import React from "react"

import SearchDropDown from "../comp/SearchDropDown"
import Tip from "../comp/Tip"
import People from "../comp/People"
import Button from "../comp/Button"
import CheckBoxes, { CheckBox } from "../comp/CheckBoxes"
import isFQDN from "validator/lib/isFQDN"
import { Template } from "./PersonAdder"
import BarLoader from "react-spinners/BarLoader"
import { enableNoCCTrial } from "../comp/StripeUtils"
import { isArchived } from "../utils/utils"

import cx from "classnames"
import Textarea from "react-textarea-autosize"
import { toast } from "react-toastify"
import isEmail from "validator/lib/isEmail"
import { getObjectId, getRefId, sortAlpha, getDomain } from "../comp/Utils"
import { PiWarningDuotone, PiQuestionDuotone } from "react-icons/pi"
import { formatMissionPeople } from "../comp/MissionUtils"
import { addParam } from "../comp/History"
import { PiTriangleDuotone, PiEnvelopeDuotone } from "react-icons/pi"
import apiAuth from "../services/auth/auth.api"

import {
    getMissionUsageByType,
    hasMissionUnlimitedPeopleUsage,
    getMissionSubscriptionPlanItemByType,
} from "./StripeUtils"

import "../comp/InviteToMission.scss"
import "../comp/PersonAdder.scss"

class InviteToMission extends React.Component {
    constructor(props) {
        super(props)

        const me = this
        const mission = this.props.missionData

        this.blockRoles = false

        const autoCreateRole =
            !props.noCreateRoleOption &&
            !this.props.missionData.isTemplate &&
            !this.props.missionData.isModel &&
            !this.blockRoles

        const aboutMe = this.props.missionData.people.find((p) => getRefId(p) === this.props.app.state.person._id)
        this.aboutMeInMission = aboutMe

        if (this.props.missionData.org) {
            const o = this.props.app.state.orgs.find(
                (org) => getObjectId(org) === getObjectId(this.props.missionData.org)
            )

            this.org = o
        }

        let searchList = []

        if (this.org) {
            this.aboutMeInOrg = (me.org.people || []).find((p) => getRefId(p) === me.props.app.state.person._id)
            const orgData = this.org
            let goodPeople = []
            let issuePeople = []

            this.org.people?.forEach((orgPerson) => {
                if (mission.people.find((p) => p.permission <= 3 && getRefId(p) === getRefId(orgPerson))) {
                    return
                }

                const myDepartment = orgPerson.departments?.length
                    ? orgData.departments?.find((d) => !isArchived(d) && d._id === orgPerson.departments[0])
                    : null
                const myOffice = orgPerson.office
                    ? orgData.offices?.find((o) => !isArchived(o) && o._id === orgPerson.office)
                    : null

                if (this.aboutMeInOrg.accessibleDepartments?.length && orgPerson.departments?.length) {
                    if (!this.aboutMeInOrg.accessibleDepartments.includes(orgPerson.departments[0])) {
                        return false
                    }
                }
                if (!myDepartment && !myOffice) {
                    goodPeople.push(orgPerson)
                } else {
                    let depOk
                    let offOk

                    if (myDepartment && mission.departmentTags?.length) {
                        if (mission.departmentTags.includes(myDepartment?._id)) {
                            depOk = true
                        } else {
                            if (myDepartment.dontShareResources) {
                                issuePeople.push({ ...orgPerson, doNotShare: true })
                                return
                            }
                            depOk = false
                        }
                    } else {
                        depOk = true
                    }
                    if (myOffice && mission.offices?.length) {
                        if (mission.offices?.includes(myOffice?._id)) {
                            offOk = true
                        } else {
                            offOk = false
                        }
                    } else {
                        offOk = true
                    }

                    if (!depOk || !offOk) {
                        issuePeople.push({ ...orgPerson, officeIssue: !offOk, departmentIssue: !depOk })
                    } else {
                        goodPeople.push(orgPerson)
                    }
                }
            })

            searchList = [...sortAlpha(goodPeople), ...sortAlpha(issuePeople)]
        }

        if (
            mission.client &&
            this.aboutMeInOrg.permission !== 2 &&
            !this.aboutMeInOrg.canApproveRoles &&
            mission.departmentTags?.includes("673c9e5d9794765732fd2125") &&
            mission.offices?.includes("66e3db6b0adca66cf7368435")
        ) {
            this.blockRoles = true
        }

        let myEmail = { verified: false }

        if (aboutMe.email === this.props.app.state.person.email) {
            myEmail.verified = this.props.app.state.person.emailVerified
        } else {
            myEmail = this.props.app.state.person.emailList.find((em) => em.email === aboutMe.email)
        }

        this.state = {
            myEmail,
            checkedBoxes: { admin: false, manager: false, team: true, observer: false },
            loading: false,
            autoCreateRole: autoCreateRole,
            verified: myEmail.verified,
            msg: this.props.missionData.isTemplate ? "Please help manage our template" : "Welcome to the mission!",
            email: null,
            searchList,
        }

        this.emailTO = 0
    }
    componentDidMount() {
        if (this.emailDropDown && this.emailDropDown.focus) {
            setTimeout(() => {
                if (this.emailDropDown && this.emailDropDown.focus) this.emailDropDown.focus()
            }, 1000)
        }
    }
    permissions(arr, ind, isChecked, obj) {
        let checks = { admin: false, manager: false, team: false, observer: false }

        checks[obj.id] = true

        if (obj.id === "observer" && isChecked) {
            this.setState({
                autoCreateRole: false,
                isPm: false,
            })
            this.permission = obj.value
        } else if (obj.id === "team" && isChecked) {
            this.setState({
                isPm: false,
            })
            this.permission = obj.value
        } else if (obj.id === "admin" || obj.id === "pm") {
            this.setState({
                isPm: true,
            })
            this.permission = obj.value
        } else if (obj.id === "manager") {
            this.setState({
                isPm: false,
            })
            this.permission = obj.value
        }

        this.setState({
            checkedBoxes: checks,
        })
    }
    doInvite() {
        const me = this

        if (!isEmail(this.state.email)) {
            toast.error("The email you entered appears incorrect.")
            return
        }

        if (this.props.missionData.people.find((p) => p.permission <= 3 && p.email === this.state.email)) {
            toast.warning(`Ooops. ${this.state.email} already exists on the mission team.`, { autoClose: 6000 })
            me.resetAll()
            return
        }

        const isThereAnOrgPerson = me.org?.people?.find((p) => p.email === this.state.email)

        if (
            isThereAnOrgPerson &&
            this.aboutMeInOrg.accessibleDepartments?.length &&
            isThereAnOrgPerson.departments?.length
        ) {
            if (
                this.aboutMeInOrg.accessibleDepartments?.length &&
                !this.aboutMeInOrg.accessibleDepartments.includes(isThereAnOrgPerson.departments[0])
            ) {
                toast.error(
                    "The sorry but this person falls outside of your accessible department's and cannot be added to the project",
                    {
                        autoClose: 6000,
                    }
                )
                return
            }
        }

        const metadata = {
            config: {
                role: "Team member",
                permission: this.permission || 0,
                isProjectManager: this.state.isPm,
            },
            message: this.state.msg,
            invitee: this.state.personData,
            autoCreateRole: this.state.autoCreateRole,
        }

        this.setState({
            loading: true,
            showButton: false,
        })

        this.props.app.missionInvitePerson(this.props.missionData._id, this.state.email, metadata, (res) => {
            if (this.props.afterInvite) {
                this.props.afterInvite(res)
            }

            me.resetAll()
        })
    }
    resetAll() {
        this.setState({
            email: null,
            emailSearch: null,
            msg: "Welcome to the project!",
            checkedBoxes: { admin: false, manager: false, team: true, observer: false },
            invitedName: null,
            loading: false,
            isPm: false,
            showDomainChangeMsg: false,
            personData: null,
        })

        setTimeout(() => {
            try {
                if (this.emailDropDown) {
                    this.emailDropDown.focus()
                }
                this.titleField.value = ""
            } catch (err) {}
        }, 800)

        this.permission = null
    }
    onEmailChange(val, paste, e) {
        clearTimeout(this.emailTO)
        const isOk = isEmail(val)

        const domains = this.props.missionData.inviteDomains

        let searchString = val ? val.trim() : ""

        const domIndex = searchString.indexOf("@")

        const hasLastChar = searchString.charAt(domIndex + 1) === "" ? false : true

        const any = domains.includes("ANY")

        let theEmail

        if (!paste && !any) {
            if (domIndex !== -1 && domains.length === 1 && !hasLastChar && !this.backspace) {
                searchString = searchString.concat(domains[0])
                e.target.blur()
            } else if (domIndex !== -1 && hasLastChar && domains.length > 1 && !this.backspace) {
                const lastPortion = searchString.substr(searchString.indexOf("@") + 1, searchString.length)

                const domainMatches = domains.filter((dm) => dm.startsWith(lastPortion))

                if (domainMatches.length === 1) {
                    let remainder = domainMatches[0].replace(lastPortion, "")
                    searchString = searchString.concat(remainder)
                    e.target.blur()
                }
            }

            const isEmailValid = isEmail(searchString?.trim())

            theEmail = isEmailValid ? searchString : null
        } else {
            theEmail = isEmail(val?.trim()) ? val : null
        }

        this.setState(
            {
                emailSearch: theEmail ? null : searchString,
                email: theEmail,
                showButton: theEmail && isEmail(theEmail?.trim()),
                invitedName: null,
            },
            () => {
                /*  if (this.state.email?.charAt(0) === "@" && this.state.emailSearch === "") {
                    resetCursor(this.emailDropDown)
                }*/

                clearTimeout(this.changeChckerTO)
                this.changeChckerTO = setTimeout(() => {
                    const showDomMsg =
                        isOk &&
                        !this.props.missionData?.inviteDomains?.includes(val.substr(val.indexOf("@") + 1, val.length))

                    this.setState({
                        showDomainChangeMsg: showDomMsg && this.props.missionData.inviteDomains.indexOf("ANY") === -1,
                    })
                }, 700)
            }
        )

        if (this.titleField) this.titleField.value = ""
    }
    isEmailOnMission(callback) {
        const me = this

        let found = false

        me.props.missionData.people.forEach((person) => {
            if (me.state.email.toLowerCase() === person.email.toLowerCase()) {
                found = true
            }
        })

        callback(found)
    }

    onSelectHandler(obj) {
        if (!obj) return

        if (obj.doNotShare) {
            toast.warning(
                "Sorry but this person belongs to a department that does not allow sharing of resources for projects outside the department. You may change the project settings to include the department if you have acccess to it.",
                { autoClose: 9000 }
            )
            return
        }

        this.setState(
            {
                email: obj.email,
                invitedName: obj.ref?.firstName || "",
                personData: obj,
                showButton: true,
                emailSearch: null,
            },
            () => {
                const me = this
                if (!me.state.error) {
                    const validDomain = this.props.missionData?.inviteDomains?.includes(getDomain(this.state.email))

                    this.setState({
                        error: me.state.email?.length && !isEmail(me.state.email),
                        showDomainChangeMsg: isEmail(this.state.email || "x") && !validDomain,
                    })
                }
            }
        )
    }
    matchPerson(obj, val) {
        if (!val) return true

        const person = obj.data

        if (!person.ref?.firstName && !person.ref?.lastName) {
            return false
        }

        let value = val

        /*const domIndex = value.indexOf("@")
        if (domIndex !== -1) {
            value = value.slice(0, domIndex - 1)
        }*/

        if (value === "" && !this.state.email) {
            return true
        } else if (value === "" && this.state.email) {
            return false
        } else if (
            (person.ref.firstName && person.ref.firstName.toLowerCase().indexOf(value.toLowerCase()) !== -1) ||
            (person.ref.lastName && person.ref.lastName.toLowerCase().indexOf(value.toLowerCase()) !== -1) ||
            (person.email && person.email.toLowerCase().indexOf(value.toLowerCase()) !== -1)
        ) {
            return true
        } else {
            return false
        }
    }
    extraPermissions(arr, index, checked, obj) {
        this[obj.value] = checked

        if (obj.value === "autoCreateRole") {
            this.setState({
                autoCreateRole: checked,
            })
        }
    }
    getExtraOptions(notFoundInOrg) {
        let options = []

        let createRoleOption = !this.props.missionData.noTimesheets

        if (this.props.noCreateRoleOption) {
            createRoleOption = false
        } else if (!this.org?.isLicensedActive) {
            createRoleOption = false
        } else if (this.state.checkedBoxes["observer"]) {
            createRoleOption = false
        } else if (this.blockRoles) {
            createRoleOption = false
        }

        if (
            !this.props.missionData.createdFromGtCrm &&
            !this.state.checkedBoxes["observer"] &&
            !this.props.missionData.isModel &&
            !this.props.missionData.isTemplate
        ) {
            if (createRoleOption) {
                options.push({
                    label: (
                        <>
                            Enable timesheets (Assign a project role)
                            <Tip title='Will automatically create a role and attach this person to it in the "Budget" panel. They will then be active and able to submit timesheets. '>
                                <PiQuestionDuotone />
                            </Tip>
                        </>
                    ),
                    value: "autoCreateRole",
                    checked: this.state.autoCreateRole,
                })
            }
        }

        return options
    }
    render() {
        const org = this.props.missionData.org
            ? this.props.app.state.orgs.find((o) => getObjectId(o) === getObjectId(this.props.missionData.org))
            : null

        const orgDepartment = org
            ? org.departments?.find((d) => this.props.missionData.departmentTags?.includes(d._id))
            : null

        const isOrgAdmin = org ? org.people?.find((p) => getRefId(p) === this.props.app.state.person._id) : false

        const isProjectManager =
            this.aboutMeInMission?.permission === 2 || this.aboutMeInMission?.isProjectManager || isOrgAdmin

        const orgData = org
        const mission = this.props.missionData

        let options = []
        let notFoundInOrg

        //if (this.state.showDomainChangeMsg) {
        if (this.org && this.state.email && isEmail(this.state.email)) {
            const dom = getDomain(this.state.email)
            notFoundInOrg = !this.org?.inviteDomains?.find((id) => id === dom)

            if (this.state.checkedBoxes["admin"] && notFoundInOrg) {
                this.permissions([], 0, true, { id: "team" })
                toast("Permission admin is not permitted for domains that are not approved by the organization.", {
                    autoClose: 4000,
                })
            }
        }
        //}

        const gtNo = this.props.missionData.createdFromGtCrm || orgDepartment?.rolesRequireApproval

        if (this.aboutMeInMission.permission === 2 || this.aboutMeInOrg?.permission === 2) {
            if (!gtNo)
                options.push({
                    value: 2,
                    id: "admin",
                    disabled: notFoundInOrg,
                    label: (
                        <p>
                            Admin - {this.props.missionData?.isTemplate ? "Template" : "Project"} owner{" "}
                            {this.state.checkedBoxes["admin"] && <>💥</>}
                            <Tip
                                title={
                                    this.props.missionData.isTemplate
                                        ? "Admins can do anything including delete the template"
                                        : notFoundInOrg
                                        ? `
                                            The email domain has not been cleared by the Organization. The person can still work on the project, but their permission is limited as Admins
                                            see potentially sensitive client data. To change this, an org admin must add "
                                            ${getDomain(this.state.email)}" to the domains in the org config panel.`
                                        : "Admins can do absolutely anything a Project manager can do AND delete the project."
                                }
                            >
                                <PiQuestionDuotone color={notFoundInOrg ? "red" : undefined} />
                            </Tip>
                        </p>
                    ),
                    checked: this.state.checkedBoxes["admin"],
                })
        }

        if (isProjectManager && !gtNo) {
            options.push({
                value: 1,
                id: "pm",
                label: (
                    <p>
                        {notFoundInOrg ? (
                            <>
                                {this.props.missionData.isTemplate
                                    ? "Template manager (Limited)"
                                    : "Project Manager (Limited)"}{" "}
                                {this.state.checkedBoxes["pm"] && <>💪</>}
                            </>
                        ) : (
                            <>
                                {this.props.missionData.isTemplate ? "Template manager" : "Project Manager"}{" "}
                                {this.state.checkedBoxes["pm"] && <>💪</>}
                            </>
                        )}
                        <Tip
                            title={
                                this.props.missionData.isTemplate
                                    ? "Template managers can do almost anything except delete the template"
                                    : notFoundInOrg
                                    ? "Although this person can act as a project manager, they will not have access to change settings, as these may contain potentially sensitive data such as client information and department details."
                                    : "PMs along with Admin's control the project plan, the budget, and approve leaves and timesheets for the team."
                            }
                        >
                            <PiQuestionDuotone color={notFoundInOrg ? "red" : undefined} />
                        </Tip>
                    </p>
                ),
                checked: this.state.checkedBoxes["pm"],
            })
        }

        if (this.aboutMeInMission.permission >= 0 && !this.props.missionData.isTemplate) {
            if (this.props.missionData.isProcess && this.props.missionData.isTemplate) {
                return
            }
            if (
                this.aboutMeInMission.permission >= 1 &&
                this.props.missionData.projectType !== "mx-gantt" &&
                !this.props.missionData.isTemplate
            ) {
                options.push({
                    value: 1,
                    id: "manager",
                    label: (
                        <p>
                            Team lead{" "}
                            <Tip title="Can create and assign tasks to project members">
                                <PiQuestionDuotone />
                            </Tip>
                        </p>
                    ),
                    checked: this.state.checkedBoxes["manager"],
                })
            }
            options.push({
                value: 0,
                id: "team",
                label: (
                    <p>
                        Team member{" "}
                        <Tip title="Team members can only work on tasks they are assigned to and cannot create new tasks">
                            <PiQuestionDuotone />
                        </Tip>
                    </p>
                ),
                checked: this.state.checkedBoxes["team"],
            })

            if (!this.props.missionData.isModel && orgData) {
                if (!this.state.email) {
                    //do nothing
                } else if (
                    orgData?.inviteDomains?.length &&
                    orgData?.inviteDomains.includes(getDomain(this.state.email || ""))
                ) {
                    //do nothing
                } else if (
                    this.props.missionData.projectType === "mx-gantt" &&
                    !this.props.missionData.isLicensedActive
                ) {
                    //do nothing
                } else {
                    options.push({
                        value: 3,
                        id: "observer",
                        label: (
                            <>
                                External Observer / Client{" "}
                                {this.org && (
                                    <>
                                        - Free* &nbsp;
                                        <Tip title="People from outside  can see progress, tasks, approve change requests and message people. That;s about it.">
                                            <PiQuestionDuotone />
                                        </Tip>
                                    </>
                                )}
                            </>
                        ),
                        checked: this.state.checkedBoxes["observer"],
                    })
                }
            }
        }

        const usage = getMissionUsageByType("people", this.props.missionData)

        const peepsToShow = formatMissionPeople(
            this.props.missionData.people.filter((p) => p.permission !== 5 && p.permission !== 3)
        )

        let howManyAreObservers = 0

        if (org) {
            howManyAreObservers = this.props.missionData.people.filter((p) => {
                return p.permission > 3
            }).length
        }

        if (
            !hasMissionUnlimitedPeopleUsage(this.props.missionData) &&
            !getMissionSubscriptionPlanItemByType("people", this.props.missionData) &&
            usage.value >= 5
        ) {
            return (
                <div className="dna-invite-card dna-invite-to-mission" id="invite-to-mission-card">
                    <div className="dna-col">
                        <h2>Time to level up 🦖</h2>
                        <br />
                        <People data={peepsToShow} />
                        <br />
                        <h3>5 of 5 free slots used.</h3>

                        {howManyAreObservers > 0 && (
                            <p className="x-inset">
                                There are {howManyAreObservers} {howManyAreObservers === 1 ? "person" : "people"} from
                                your Organization that {howManyAreObservers === 1 ? "is" : "are"} using a free seat on
                                this project. Org managers count as a seat because although not directly involved they
                                get all the insights and reports and can follow the project.{" "}
                            </p>
                        )}

                        {org && !org.isLicensedActive ? (
                            <>
                                <p>Why not start a free 30 day trial for your organization?</p>
                                <Button
                                    red
                                    onClick={() => {
                                        if (this.aboutMeInOrg.permission === 1 || this.aboutMeInOrg.permission === 2) {
                                            enableNoCCTrial({
                                                org,
                                                app: this.props.app,
                                            })
                                        } else {
                                            toast("Ooops. Please ask an organization manager to activate the license.")
                                        }
                                    }}
                                >
                                    Start a free, unlimited trial
                                </Button>
                                <br />
                                <br />
                            </>
                        ) : (
                            <div
                                className="dna-pill-box x-pill click-able"
                                onClick={(e) => {
                                    addParam("mc", "open")
                                }}
                            >
                                Upgrade just your project
                            </div>
                        )}
                    </div>
                </div>
            )
        }

        const extraOptions = this.getExtraOptions(notFoundInOrg)

        const orgWarning =
            !orgData?.inviteDomains?.includes("ANY") &&
            !this.state.error &&
            this.state.email &&
            isFQDN(getDomain(this.state.email)) &&
            notFoundInOrg &&
            this.props.missionData?.inviteDomains?.includes(getDomain(this.state.email))

        return (
            <div className="dna-invite-to-mission" id="invite-to-mission-card">
                <div
                    className="x-action-bg"
                    style={{ backgroundImage: this.props.missionData.theme?.backgroundImage }}
                ></div>

                {this.state.verified && (
                    <div
                        className={cx("dna-avatar-round x-inv-avatar", { filled: this.state.personData })}
                        style={{
                            backgroundImage: this.state.personData?.ref
                                ? `url(${this.state.personData.ref.avatar})` || "url(/img/avatar-blur-red.jpg)"
                                : "url(/img/avatar-my-1.jpg)",
                        }}
                    ></div>
                )}

                {this.state.loading && (
                    <div className="dna-col">
                        <p style={{ marginTop: 90 }}>Sending invite...</p>

                        <BarLoader color={"aqua"} loading={this.state.loading} />
                    </div>
                )}

                {!this.state.loading && (
                    <>
                        {!this.state.verified && (
                            <>
                                <div className="x-inset w-500" style={{ marginTop: 40 }}>
                                    <div className="dna-flex">
                                        <img src="/img/mAX.png" style={{ width: 80, marginRight: 10 }} />
                                        <div>
                                            <p>
                                                <b>Houston... we have an unverified email.</b>
                                            </p>
                                            <p>
                                                Mission safety comes first. Please take a moment to validate your email
                                                address so we can invite your team.
                                            </p>

                                            <p
                                                className="x-link"
                                                onClick={() => {
                                                    apiAuth
                                                        .sendEmailVerification(this.state.myEmail.email)
                                                        .then(() => {
                                                            toast.success("Verification email on it's way!")
                                                            if (this.props.onCancel) this.props.onCancel()
                                                        })
                                                        .catch((err) => {
                                                            toast.error("Already on its way. Please check your email.")
                                                        })
                                                }}
                                            >
                                                Resend authentication email
                                            </p>
                                            <div style={{ textAlign: "right" }}>
                                                <b>mAX</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {this.state.verified && (
                            <>
                                {" "}
                                <h3>Mission invite</h3>
                                {this.props.missionData.isModel ? (
                                    <h4>
                                        Invite someone to help estimate this
                                        <br /> model project for the proposal.{" "}
                                    </h4>
                                ) : this.props.missionData.isTemplate ? (
                                    <h4>Invite someone to help craft the template.</h4>
                                ) : this.props.missionData.isProposal ? (
                                    <h4>Invite someone to help prepare the proposal.</h4>
                                ) : (
                                    <h4>Invite a new team member</h4>
                                )}
                                <SearchDropDown
                                    wrapperClassName={cx("w-500 dna-center-text x-inv-search", {
                                        "squigly-red": this.state.error,
                                    })}
                                    noAlpha={true}
                                    maxItems={100}
                                    value={this.state.email}
                                    shouldItemRender={this.matchPerson.bind(this)}
                                    data={this.state.searchList}
                                    replaceTemplate={true}
                                    selectOnBlur={false}
                                    noEmptyMenu={true}
                                    noResultsText="Inviting a person oustside the exisiting team..."
                                    onSelect={(label, obj) => {
                                        this.onSelectHandler(obj)
                                    }}
                                    showClearIcon={true}
                                    onClear={() => {
                                        this.setState({
                                            email: null,
                                            personData: null,
                                            error: false,
                                            showDomainChangeMsg: false,
                                        })
                                    }}
                                    onBlur={() => {
                                        const me = this
                                        if (!me.state.error) {
                                            const validDomain = this.props.missionData?.inviteDomains?.includes(
                                                getDomain(this.state.email)
                                            )

                                            this.setState({
                                                error: me.state.email?.length && !isEmail(me.state.email),
                                                showDomainChangeMsg: isEmail(this.state.email || "x") && !validDomain,
                                            })
                                        }
                                    }}
                                    onChange={(text) => {
                                        this.setState({
                                            showDomainChangeMsg: false,
                                            email: text,
                                            error: text.includes("@") && !isEmail(text.trim()),
                                        })
                                    }}
                                    onKeyDown={(e) => {
                                        e.persist()
                                        if (
                                            this.state.email?.length >= 1 &&
                                            e.key === "@" &&
                                            this.props.missionData.inviteDomains.length === 1 &&
                                            this.props.missionData.inviteDomains[0] !== "ANY"
                                        ) {
                                            e.target.blur()
                                            e.stopPropagation()
                                            e.preventDefault()
                                            this.setState(
                                                {
                                                    error: false,
                                                    email:
                                                        this.state.email +
                                                        "@" +
                                                        this.props.missionData.inviteDomains[0],
                                                },
                                                () => {}
                                            )
                                        }

                                        if (e.key === "Enter") {
                                            setTimeout(() => {
                                                e.target.blur()
                                            }, 100)
                                        }
                                    }}
                                    placeHolder="Find an existing team member or enter a new email..."
                                    template={(personDetails, isHighlighted) => {
                                        return (
                                            <span>
                                                <Template
                                                    personDetails={personDetails}
                                                    isHighlighted={isHighlighted}
                                                    orgData={orgData}
                                                    app={this.props.app}
                                                    mission={mission}
                                                    onSelectHandler={() => {
                                                        this.onSelectHandler(personDetails)
                                                    }}
                                                    comp={
                                                        <>
                                                            {personDetails.doNotShare ? (
                                                                <p
                                                                    style={{ marginTop: 4 }}
                                                                    className="x-inset-light dna-smaller-text orange"
                                                                >
                                                                    <PiTriangleDuotone color={"red"} /> This person's
                                                                    department does not allow people to work on projects
                                                                    outside of their department.
                                                                </p>
                                                            ) : (
                                                                <>
                                                                    {(personDetails.officeIssue ||
                                                                        personDetails.departmentIssue) && (
                                                                        <p
                                                                            style={{ marginTop: 4 }}
                                                                            className="x-inset-light dna-smaller-text orange"
                                                                        >
                                                                            <PiTriangleDuotone color={"orange"} />{" "}
                                                                            Department or Office does not match the
                                                                            project's settings.
                                                                        </p>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    }
                                                />
                                            </span>
                                        )
                                    }}
                                />
                                <div
                                    className={cx("x-inset-light w-500 orange dna-light x-inv-show-add-dom", {
                                        open: this.state.showDomainChangeMsg,
                                    })}
                                    style={{ marginBottom: 10 }}
                                >
                                    <p>
                                        <b>
                                            <PiWarningDuotone color="orange" /> Unapproved email domain detected
                                        </b>
                                    </p>
                                    <p>This email has not been authorized in project settings.</p>
                                    {isProjectManager && (
                                        <CheckBox
                                            controlled
                                            label={
                                                <>
                                                    Authorize people from &nbsp;
                                                    <span className="dna-hl red">
                                                        {this.state.email?.trim()?.split("@")[1]}
                                                    </span>
                                                    ?
                                                </>
                                            }
                                            onChange={(a, b, checked) => {
                                                if (checked) {
                                                    const newDomain = this.state.email?.trim()?.split("@")[1]

                                                    if (isFQDN(newDomain)) {
                                                        let newDomains = this.props.missionData.inviteDomains.slice()
                                                        newDomains.push(newDomain)
                                                        this.props.app.missionUpdate(this.props.missionData._id, {
                                                            inviteDomains: newDomains,
                                                        })

                                                        toast.success(
                                                            newDomain +
                                                                " has been cleared for the project. You can change domains in your project settings",
                                                            {
                                                                autoClose: 6000,
                                                            }
                                                        )

                                                        this.setState({
                                                            showDomainChangeMsg: false,
                                                        })
                                                    } else {
                                                        toast(
                                                            "Sorry but that domain may be invalid. Please try again or contact us for help. help@missionx.ai",
                                                            {
                                                                autoClose: 5000,
                                                            }
                                                        )
                                                    }

                                                    //  this.props.app
                                                }
                                            }}
                                        />
                                    )}
                                </div>
                                <h4>Include a personalized message</h4>
                                <Textarea
                                    id="inv-msg"
                                    className="w-500 inv-text"
                                    onChange={(e, obj) => {
                                        this.setState({ msg: e.target.value })
                                    }}
                                    value={this.state.msg}
                                />
                                <h4>Permission</h4>
                                <CheckBoxes controlled onChange={this.permissions.bind(this)} data={options} />
                                {extraOptions.length > 0 && !orgDepartment?.rolesRequireApproval && (
                                    <>
                                        <h4>Optional</h4>
                                        <CheckBoxes
                                            controlled
                                            key={extraOptions[0].disabled + "-cb-" + extraOptions.length}
                                            onChange={this.extraPermissions.bind(this)}
                                            data={extraOptions}
                                        />
                                    </>
                                )}
                                {this.blockRoles && (
                                    <div className="x-inset-light">
                                        <p>
                                            Please note: You may invite people to observe the project but only OERA
                                            approved people can be assigned a role from the Scheduling tab in the pm's
                                            WORK SPACE
                                        </p>
                                    </div>
                                )}
                                <br />
                                <div className="dna-center " style={{ paddingBottom: 10 }}>
                                    {isEmail(this.state.email || "") && (
                                        <Button
                                            className="x-mission-invite-button"
                                            style={{
                                                marginLeft: 70,
                                                marginRight: 0,

                                                paddingRight: 40,
                                            }}
                                            animated
                                            flip
                                            icon
                                            dark
                                            spaceRight
                                            disabled={this.state.showDomainChangeMsg}
                                            onClick={this.doInvite.bind(this)}
                                        >
                                            <PiEnvelopeDuotone color="aqua" /> Send invite
                                        </Button>
                                    )}
                                    &nbsp;
                                    <Button clear onClick={this.props.onCancel}>
                                        Cancel
                                    </Button>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        )
    }
}

InviteToMission.displayName = "InviteToMission"
export default InviteToMission
