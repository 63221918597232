import { openDB } from "idb"

// Initialize database with dynamic DB name and store name
export const initDB = async (dbName, storeName, keyPath = "id") => {
    return openDB(dbName, 17, {
        upgrade(db, oldVersion, newVersion, transaction) {
            if (oldVersion < 17) {
                // Remove existing object store to invalidate previous data
                if (db.objectStoreNames.contains(storeName)) {
                    db.deleteObjectStore(storeName)
                }
                // Create a new object store
                db.createObjectStore(storeName, { keyPath })
            }
        },
    })
}

export const addDbItem = async (dbName, storeName, item) => {
    try {
        const db = await initDB(dbName, storeName) // keyPath is handled during initDB

        if (!item.id) {
            // Ensure the item has an 'id' field
            throw new Error(`Item must have a unique identifier in "id".`)
        }

        await db.put(storeName, item) // Insert or update the item

        return item
    } catch (error) {
        throw error
    }
}

// Get an item by ID from the database with a dynamic store name
export const getDbItem = async (dbName, storeName, id) => {
    try {
        const db = await initDB(dbName, storeName)
        return await db.get(storeName, id) // Fetch item by ID
    } catch (error) {
        throw error
    }
}

// Get all items from the database with a dynamic store name
export const getAllItems = async (dbName, storeName) => {
    try {
        const db = await initDB(dbName, storeName)
        return await db.getAll(storeName) // Fetch all items
    } catch (error) {
        throw error
    }
}

// Delete an item by ID from the database with a dynamic store name
export const deleteDbItem = async (dbName, storeName, id) => {
    try {
        const db = await initDB(dbName, storeName)
        return await db.delete(storeName, id) // Delete item by ID
    } catch (error) {
        console.error("Failed to delete item:", error)
        throw error
    }
}
