import React, { useState, useEffect } from "react"
import { getRefId, getPersonName, getRolesText, getPersonLocation } from "../utils/utils"
import Tip from "./Tip"
import RoundAvatar from "./RoundAvatar"
import Card from "./Card"
import InviteToMission from "../comp/InviteToMission"
import { Scrollbars } from "react-custom-scrollbars"
import cx from "classnames"
import Flag from "react-flagkit"
import "./Hive.scss"
import { motion, AnimatePresence } from "framer-motion"
import { PiTriangleDuotone, PiArrowArcLeftLight } from "react-icons/pi"
import { TbLocationPlus } from "react-icons/tb"
import PersonAdder from "./PersonAdder"
import uniqBy from "lodash/uniqBy"
import { getCustomRoleLabelByCustomRole } from "../comp/MissionUtils"

const Hive = ({
    people,
    orgData,
    mission,
    width = 30,
    onClick,
    maxLength,
    app,
    noFlip,
    add,
    onAfterInvite,
    style,
    onAdd,
    adderMsg,
    ai,
    onRemove,
    searchList,
    offset,
    showPointer,
    round,
    adderCardStyle = {},
    adderPortal,
    appendTo,
    adderPortalCenter,
    inviteMsg,
    onAddOpened,
    onAddClosed,
}) => {
    let peopleToUse = mission ? people.filter((p) => p?.permission <= 3) : people || []

    peopleToUse = uniqBy(
        (peopleToUse || []).map((p) => ({ ...p, rf: getRefId(p) })),
        "rf"
    )

    let map = [...peopleToUse.slice(0, maxLength || people.length)]

    const [inviting, setInviting] = useState()
    const [adding, setAdding] = useState()

    const meInMission = mission?.people.find((p) => getRefId(p) === app.state.person._id)
    const meInOrg = orgData?.people?.find((p) => getRefId(p) === app.state.person._id)

    if (maxLength && maxLength < peopleToUse.length) {
        map.push({
            plus: peopleToUse.length - maxLength,
            _id: "plus" + (mission._id || orgData._id),
        })
    }
    if (add) {
        map.push({
            add: true,
            _id: "add" + (mission._id || orgData._id),
        })
    }

    useEffect(() => {
        if (adding && onAddOpened) {
            onAddOpened()
        } else if (onAddClosed && !adding) {
            onAddClosed()
        }
    }, [adding, onAddOpened, onAddClosed])

    return (
        <>
            <ul className="x-hive" onClick={onClick} style={{ marginLeft: round ? 0 : width * 0.7, ...style }}>
                <AnimatePresence>
                    {map.map((obj, index) => {
                        return (
                            <Person
                                key={getRefId(obj)}
                                plus={obj.plus}
                                add={obj.add}
                                person={obj}
                                orgData={orgData}
                                mission={mission}
                                width={width}
                                index={index}
                                people={people}
                                meInMission={meInMission}
                                meInOrg={meInOrg}
                                maxLength={maxLength}
                                app={app}
                                onAdd={onAdd}
                                setInviting={setInviting}
                                setAdding={setAdding}
                                onRemove={onRemove}
                                offset={offset}
                                ai={ai}
                                round={round}
                                inviteMsg={inviteMsg}
                            />
                        )
                    })}
                </AnimatePresence>
            </ul>

            <AnimatePresence>
                {showPointer && people.length <= 1 && (
                    <motion.div
                        style={{ pointerEvents: "none", position: "absolute", left: 70, top: 20 }}
                        initial={{
                            opacity: 0,
                            x: 20,
                        }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{
                            opacity: 0,
                            x: 20,
                        }}
                        transition={{ duration: 1, delay: 1, type: "spring", bounce: 0.6 }}
                    >
                        <PiArrowArcLeftLight /> Invite people
                    </motion.div>
                )}
            </AnimatePresence>

            <Card closeButton modal modalIndex={9997} portal center onClose={() => setInviting(false)} open={inviting}>
                <Scrollbars className="dna-invite-scroll" autoHeight={true} autoHeightMin={400} autoHeightMax={"97vh"}>
                    {inviting && (
                        <InviteToMission
                            app={app}
                            missionData={mission}
                            afterInvite={(res) => {
                                if (onAfterInvite) onAfterInvite(res)
                            }}
                            inviteToMission={() => {
                                setInviting(false)
                            }}
                            onCancel={() => {
                                setInviting(false)
                            }}
                        />
                    )}
                </Scrollbars>
            </Card>

            <Card
                padded
                style={{ paddingTop: 0, width: 400, ...adderCardStyle }}
                className="dna-add-people-card x-ai-adder-style"
                onClose={() => setAdding(false)}
                open={adding}
                autoClose={true}
                portal={adderPortal}
                appendTo={appendTo}
                center={adderPortalCenter}
            >
                {adding && (
                    <PersonAdder
                        org={orgData}
                        mission={mission}
                        people={people}
                        searchList={searchList || mission?.people.filter((p) => p.permission < 3)}
                        onAdd={onAdd}
                        app={app}
                        adderMsg={adderMsg}
                        startInvite={() => {
                            setAdding(false)
                            setInviting(true)
                        }}
                    />
                )}
            </Card>
        </>
    )
}

const Person = ({
    person,
    orgData,
    mission,
    width,
    index,
    adder,
    plus,
    add,
    people,
    app,
    meInOrg,
    meInMission,
    noFlip,
    maxLength,
    setInviting,
    setAdding,
    onAdd,
    onRemove,
    offset,
    ai,
    round,
    roundSpace = 3,
    inviteMsg,
}) => {
    const personToUse = person

    const even = index % 2

    const notSeen = personToUse?.email && ai && !ai.seen?.find((o) => o.by === getRefId(personToUse))

    const liStyle = {
        width: width,
        height: width,
        marginTop: round ? 0 : even ? width - width * 0.21 : 0,
        borderRadius: round ? "50%" : width * 0.2,
        marginLeft: round ? roundSpace : -width * 0.21,
        filter: notSeen ? "blur(2px)" : undefined,
    }

    const divStyle = {
        width: width * 1.3,
        height: width * 1.3,
        backgroundImage: add
            ? "linear-gradient(to right,  #ff4e50, #f9d423)"
            : plus
            ? "linear-gradient(-45deg, #833ab4, #fd1d1d, #fcb045)"
            : `url(${personToUse.ref?.avatar})`,
    }

    const cn = cx({
        pending: personToUse?.invitePending,
    })

    return (
        <motion.li
            key={getRefId(personToUse)}
            className={cn}
            initial={{
                opacity: 0,
                transform: "rotate(45deg) scale(0.2)",
            }}
            animate={{ opacity: notSeen ? 0.7 : 1, transform: "rotate(45deg) scale(1)" }}
            exit={{
                opacity: 0,
                transform: "rotate(45deg) scale(0.2)",
            }}
            transition={{ duration: 0.2, delay: 0.25 * index }}
            style={liStyle}
        >
            <Tip
                interactive={true}
                interactiveBorder={30}
                distance={plus || add ? 10 : -10}
                delay={400}
                offset={offset}
                position={plus ? "right" : index % 2 !== 0 ? "bottom" : "top"}
                html={
                    add ? (
                        <>{inviteMsg || "Invite a new team member"}</>
                    ) : plus ? (
                        <Plus
                            people={people}
                            meInOrg={meInOrg}
                            meInMission={meInMission}
                            mission={mission}
                            orgData={orgData}
                            noFlip={noFlip}
                            maxLength={maxLength}
                            app={app}
                            onRemove={onRemove}
                            ai={ai}
                        />
                    ) : (
                        <Details
                            person={person}
                            meInOrg={meInOrg}
                            meInMission={meInMission}
                            mission={mission}
                            orgData={orgData}
                            noFlip={noFlip}
                            maxLength={maxLength}
                            app={app}
                            onRemove={onRemove}
                            ai={ai}
                        />
                    )
                }
            >
                <div
                    className="x-hive-div"
                    style={divStyle}
                    onClick={
                        !add
                            ? undefined
                            : (e) => {
                                  if (!add) return
                                  e.stopPropagation()
                                  e.preventDefault()

                                  if (onAdd) {
                                      setAdding(true)
                                  } else if (add) {
                                      setInviting(true)
                                  }
                              }
                    }
                >
                    {plus && <span style={{ fontSize: width / 2.6 }}>+{plus}</span>}
                    {add && (
                        <span style={{ marginLeft: 1, marginTop: -1, fontSize: width / 1.7 }}>
                            <TbLocationPlus />
                        </span>
                    )}
                </div>
            </Tip>
        </motion.li>
    )
}

const Details = ({ mission, person, meInOrg, meInMission, orgData, app, onRemove, ai }) => {
    const personInOrg = orgData?.people?.find((p) => getRefId(p) === getRefId(person))
    const personInMission = mission?.people?.find((p) => getRefId(p) === getRefId(person))

    const personToUse = personInMission || personInOrg

    const canAdmin =
        meInOrg?.permission === 2 ||
        meInMission?.permission === 2 ||
        (meInMission?.permission === 1 && meInMission?.isProjectManager)

    const myOrgRole = personInOrg
        ? orgData.roles?.find((r) => {
              return r._id === personInOrg.role
          })
        : null
    const myDepartment = personInOrg?.departments?.length
        ? orgData.departments?.find((r) => {
              return r._id === personInOrg.departments[0]
          })
        : null

    const myOffice = personInOrg
        ? orgData.offices?.find((r) => {
              return r._id === personInOrg.office
          })
        : null

    const missionRoles = mission ? getRolesText({ mission, person: personToUse, orgData }) : []

    return (
        <div className="x-hive-details">
            <RoundAvatar person={personToUse} noTip width={68} style={{ marginRight: 20 }} />
            <div>
                <h3>{getPersonName(personToUse)}</h3>
                {myOrgRole?.name && <p>{myOrgRole?.name}</p>}
                <p
                    className="no-wrap x-link"
                    style={{ marginBottom: 10 }}
                    onClick={() => {
                        window.location.href = "mailto:" + personToUse?.email
                    }}
                >
                    {personToUse?.email}
                </p>

                {personInMission && mission && (
                    <>
                        <p style={{ marginBottom: 5 }}>
                            <b>
                                {personInMission.customRole?.length > 0
                                    ? person.customRole.map((c) => getCustomRoleLabelByCustomRole(c)).join(", ")
                                    : personInMission.isProjectManager
                                    ? "Project manager"
                                    : personInMission.permission === 2
                                    ? "Admin/Owner"
                                    : personInMission.permission === 1
                                    ? "Team lead"
                                    : personInMission.permission === 0
                                    ? "Team member"
                                    : "Observer"}
                            </b>
                        </p>
                        <p style={{ marginBottom: 10 }} className="no-wrap">
                            Project roles:{" "}
                            {missionRoles?.length ? (
                                missionRoles.join(", ")
                            ) : (
                                <>
                                    <PiTriangleDuotone color={"orange"} /> Not assigned any roles
                                </>
                            )}
                        </p>
                    </>
                )}

                {personInMission?.invitePending && (
                    <p style={{ marginBottom: 15 }} className="x-inset-light orange">
                        <PiTriangleDuotone color={"red"} /> The mission invite has not yet been accepted
                    </p>
                )}

                {personInOrg && (
                    <div style={{ opacity: 0.7 }}>
                        <p>
                            Department: {myDepartment?.name || "Not assigned"}{" "}
                            <>
                                {myOffice ? (
                                    <>
                                        <span className="x-flag" style={{ marginLeft: 6, marginRight: 3 }}>
                                            <Flag country={myOffice.country} width={15} />
                                        </span>{" "}
                                        {myOffice.title}
                                    </>
                                ) : (
                                    <>&nbsp; {getPersonLocation(personInMission)}</>
                                )}
                            </>
                        </p>
                    </div>
                )}

                {((mission && canAdmin) || (ai && onRemove)) && (
                    <span
                        style={{ marginTop: 10, display: "inline-block" }}
                        className="x-link caps gold small"
                        onClick={() => {
                            if (onRemove) {
                                onRemove(getRefId(person))
                            } else {
                                if (getRefId(person) === app.state.person._id) {
                                    app.confirm({
                                        severe: true,
                                        comp: (
                                            <p>
                                                Are you sure you want to remove yourself from the mission? If you are
                                                the only admin/pm the project can will only be found from the
                                                organization workspace.
                                            </p>
                                        ),
                                        onYes: () => {
                                            app.missionDeletePerson({
                                                person: person,
                                                noToast: true,
                                                mission: mission,
                                            })
                                        },
                                    })
                                } else {
                                    app.missionDeletePerson({
                                        person: person,
                                        noToast: true,
                                        mission: mission,
                                    })
                                }
                            }
                        }}
                    >
                        Remove this person
                    </span>
                )}
            </div>
        </div>
    )
}

const Plus = ({
    people,
    maxLength,
    orgData,
    meInOrg,
    meInMission,
    personInMission,
    personInOrg,
    mission,
    noFlip,
    app,
    ai,
}) => {
    return (
        <div className="x-hive-plus">
            {people.slice(maxLength, people.length).map((person) => {
                return (
                    <Details
                        person={person}
                        meInOrg={meInOrg}
                        meInMission={meInMission}
                        personInMission={personInMission}
                        personInOrg={personInOrg}
                        mission={mission}
                        orgData={orgData}
                        noFlip={noFlip}
                        maxLength={maxLength}
                        app={app}
                        ai={ai}
                    />
                )
            })}
        </div>
    )
}

export default Hive
